<template>
  <v-layout class="mb-4 pb-4" wrap align-start justify-center row fill-height>
    <v-flex xs12>
      <Title title="Nueva Direccion" subtitle=""></Title>
    </v-flex>
    <v-flex xs12>
      <v-card>
        <v-card-text>
          <v-container grid-list-lg fluid>
            <v-layout row wrap>
              <v-flex xs12 sm12 md6>
                <v-select
                  v-model="type"
                  :items="listTypes"
                  item-text="name"
                  item-value="id"
                  label="Tipo"
                  hide-details
                ></v-select>
              </v-flex>
              <v-flex xs12 sm12 md6>
                <v-text-field v-model="name" label="Nombre" hide-details></v-text-field>
              </v-flex>
              <v-flex xs12 sm12 md8>
                <v-text-field v-model="street" label="Direccion" hide-details></v-text-field>
              </v-flex>
              <v-flex xs12 sm12 md4>
                <v-text-field v-model="street_number" label="Direccion numero" hide-details></v-text-field>
              </v-flex>
              <v-flex xs12 sm12 md6>
                <v-select
                  v-model="comuna"
                  :items="listStates"
                  item-text="name"
                  item-value="id"
                  label="Comuna"
                ></v-select>
              </v-flex>
              <v-flex xs12 sm12 md6>
                <v-radio-group v-model="active" :mandatory="false" row>
                  <v-radio color="secondary" label="Inactivo" :value="false"></v-radio>
                  <v-radio color="secondary" label="Activo" :value="true"></v-radio>
                </v-radio-group>
              </v-flex>
              <v-flex xs12 sm12 md12>
                <div class="pb-3">
                  <GmapAutocomplete
                    placeholder="Ubicación"
                    class="input-search ma-0"
                    style="width: 100%"
                    :value="addressSearch"
                    @place_changed="setPlace($event)"
                  ></GmapAutocomplete>
                </div>
                <gmap-map
                  :id="`map${id}`"
                  :ref="`mapRef${id}`"
                  :center="center"
                  :zoom="zoom"
                  :options="options"
                  :style="`width:100%;height:250px;`"
                >
                  <GmapMarker :position="center" :clickable="false" :draggable="true" @dragend="dragend" />
                </gmap-map>
              </v-flex>
              <v-flex xs12>
                <v-textarea
                  v-model="alert"
                  rows="3"
                  :label="'Advertencia al seleccionar opcion'"
                  placeholder=""
                  hint="No es obligatorio :)"
                ></v-textarea>
              </v-flex>
            </v-layout>
          </v-container>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="accent" :loading="loading" :disabled="loading" flat @click="save"> Listo </v-btn>
        </v-card-actions>
      </v-card>
    </v-flex>
  </v-layout>
</template>

<script>
import { CONFIG, GET_STATUS_STORE } from '../../config'
import Title from '../useful/title.vue'

export default {
  name: 'CMSId',
  components: {
    Title
  },
  props: ['id'],
  data() {
    return {
      type: null,
      name: '',
      street: '',
      street_number: '',
      comuna: '',
      alert: '',
      latitude: 0,
      longitude: 0,
      active: false,
      loading: false,
      latLngOrder: { lat: -33.4262, lng: -70.6429 },
      center: { lat: -33.4262, lng: -70.6429 },
      zoom: 16,
      options: {
        styles: [],
        mapTypeControl: false,
        streetViewControl: false,
        fullscreenControl: false,
        disableDefaultUi: false
      },
      listStates: [],
      listTypes: [
        {
          id: 'DrParticular',
          name: 'Dirección particular',
          namePlural: 'direcciones particulares',
          info: 'Departamento, Villa, Sector, etc'
        },
        {
          id: 'DrEmpresa',
          name: 'Empresa o institución',
          namePlural: 'empresas o instituciones',
          info: 'Empresa o institución, oficina, cargo de la persona, etc.'
        },
        {
          id: 'CrClinica',
          name: 'Clínica u hospital',
          namePlural: 'clínicas o hospitales',
          info: 'Clinica, Habitación, sector, paciente o cargo de la persona, etc.'
        },
        {
          id: 'CrVelatorio',
          name: 'Iglesia o Velatorio',
          namePlural: 'iglesias o velatorios',
          info: 'Nombre de la iglesia, referencias dirección, etc.'
        }
      ]
    }
  },
  computed: {
    addressSearch() {
      if (this.street !== '' || this.street_number !== '') {
        return `${this.street} ${this.street_number}`
      }
      return ''
    }
  },
  mounted() {
    this.searchStatesSchedule()
    if (this.id !== 'new') {
      this.$http(`${CONFIG}/address/${this.id}`).then((res) => {
        const item = res.data
        console.log(item)
        this.name = item[0].name
        this.active = item[0].active
        this.type = item[0].type
        this.street = item[0].street
        this.street_number = item[0].number
        this.comuna = item[0].comuna.id
        this.latitude = item[0].latitude
        this.longitude = item[0].longitude
        this.alert = item[0].alert
        const position = {
          lat: item[0].latitude,
          lng: item[0].longitude
        }
        this.center = position
      })
    }
  },
  methods: {
    async searchStatesSchedule() {
      try {
        const res = await this.$http.get(GET_STATUS_STORE, {
          params: {
            all: true
          }
        })
        this.listStates = this._.orderBy(res.data, ['name'], ['asc'])
      } catch (error) {
        this.$store.dispatch('changeSnack', {
          active: true,
          text: 'Ocurrio un error, intente nuevamente'
        })
      }
    },
    async save() {
      this.loading = true
      try {
        const res = await this.$http({
          method: this.id ? 'put' : 'post',
          url: this.id ? `${CONFIG}/address/${this.id}` : `${CONFIG}/address`,
          data: {
            name: this.name,
            type: this.type,
            street: this.street,
            street_number: this.street_number,
            comuna: this.comuna,
            longitude: this.longitude,
            latitude: this.latitude,
            active: this.active,
            alert: this.alert
          }
        })
        this.loading = false
        if (this.id === 'new') {
          this.$router.replace({
            name: 'settingsAddressEdit',
            params: { id: res.data }
          })
        }
      } catch (error) {
        this.$store.dispatch('changeSnack', {
          active: true,
          text: 'Ocurrio un error, intente nuevamente'
        })
      }
    },
    setPlace(e) {
      const position = {
        lat: e.geometry.location.lat(),
        lng: e.geometry.location.lng()
      }
      this.latitude = e.geometry.location.lat()
      this.longitude = e.geometry.location.lng()
      this.center = position
      this.zoom = this.zoom === 15 ? 16 : 15
    },
    dragend(e) {
      console.log(e)
      const position = {
        lat: e.latLng.lat(),
        lng: e.latLng.lng()
      }
      this.latitude = e.latLng.lat()
      this.longitude = e.latLng.lng()
      this.center = position
    }
  }
}
</script>

<style>
.gmap {
  position: relative;
}
.input-search {
  outline: none;
  padding: 12px 12px;
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-radius: 3px !important;
}
.input-search:focus {
  border: 1px solid #f44336;
}
.input-search::placeholder {
  font-style: 'italic';
  color: rgb(143, 143, 143);
  padding: 10px 2px 10px 2px;
}
.map-icon-label .map-icon {
  font-size: 24px;
  color: #ffffff;
  line-height: 48px;
  text-align: center;
  white-space: nowrap;
}
.label {
  background: #000;
}
</style>
